import React from 'react'
import NavBar from '../Nav/Navbar'

const Artist = () =>{

    return(
        <div>
            <NavBar />
            Artist Pages Coming Soon
        </div>
    )
}

export default Artist