import React from 'react'
import Navbar from '../Nav/Navbar'

const Faq = () => {

    return(
        <div>
            <Navbar/>
            Faq's coming soon
        </div>
    )
}

export default Faq