import React from 'react'
import Navbar from '../Nav/Navbar'

const Aftercare = () => {

    return(
        <div>
            <Navbar/>
            Aftercare section coming soon
        </div>
    )
}

export default Aftercare