import React from 'react'
import NavBar from '../Nav/Navbar'


const Space = () => {

    return(
        <div>
             <NavBar />
            The Space is Coming Soon!
        </div>
    )
}

export default Space