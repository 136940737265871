import {React, useState, useEffect} from 'react'
import Field from '../InputFields/Field'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import axios from 'axios'
import URL from '../Utilites/Url'
import UserSetPassword from './UserSetPassword'
import NavBar from '../Nav/Navbar'
import './signincss.css'

const SignIn = () =>{

    const [userEmail, setUserEmail] = useState('')
    const [userPassword, setUserPassword] = useState('')
    const [loggedIn, setLoggedIn] = useState(null)
    const [initalLogin, setInitialLogin] = useState(false)
    const [displayButton, setDisplayButton] = useState(true)
   
    

    const history = useHistory()


    const displayLoading = () => {
        return <div className='signing-you-in'>Signing You In...</div>
    }

    const userEmailInput = (e) =>{
        e.preventDefault()
        setUserEmail(e.target.value)
    }
    const userPasswordInput = (e) =>{
        e.preventDefault()
        setUserPassword(e.target.value)
    }

    const submitHandler = (e) =>{
        e.preventDefault()
        const user = {
            email: userEmail,
            password: userPassword
        }
        setDisplayButton(false)
        axios.post(URL + '/login', {user}, {withCredentials: true, credentials:"include"})
        .then(response =>{
            
            if(!response.data.failure && response.data.user.inital_login){
                setInitialLogin(true)
            }
            else if (!response.data.failure){
                
                let rxdUser = response.data.user
                localStorage.setItem("token", response.data.jwt)

                rxdUser.administrator ? history.push('/admin') : history.push(`/users/${response.data.user.id}`)
            }else{
                setDisplayButton(true)
                alert(response.data.failure)
            }
            
            
        })
        
    }  
    
    const displayForm = () =>{
        return(
            <div>
                <NavBar />
                
                <div className="sign-in-div">
                <h1 className="sign-in-mess">Please Sign In:</h1>
                    <form className='sign-in-form' onSubmit={e => submitHandler(e)}>
                        <Field id='email' placeholder={'Enter Email'} userEmail={userEmail} changeHandler={e=> userEmailInput(e)} /><br></br>
                        <Field id='password' placeholder={'Enter Password'} userPassword={userPassword} changeHandler={e=> userPasswordInput(e)} />
                        {displayButton ? <button className='sign-in-button'>Sign In</button> : displayLoading()}
                    </form><br></br>
                    
                    <div>
                        <div className='recovery-creation-links'><Link to='/password-reset'>Forgot Password</Link></div>
                        <div className='recovery-creation-links'><Link to='/create-profile'>Create An Account</Link></div>
                    </div>
                </div>
            </div>
            
        )
    }

    const displaySetPasswordForm = () => {
       return <UserSetPassword email={userEmail} />
    }

    const displayDecider = () => {
        if(!loggedIn && initalLogin){
            return displaySetPasswordForm()
        }else{
            return displayForm()
        }
    }

    

    const redirectToHomePage = () => history.push(`/`)

    return(
        <div>
            
            {!loggedIn ? displayDecider() : redirectToHomePage()}
        </div>
        
    )
}

export default SignIn